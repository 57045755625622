export const BG_COVER = `https://ik.imagekit.io/zaanfa2/dhea-jordy/cover.jpg?updatedAt=1695350123578`;
export const BG_COUNTING = `https://ik.imagekit.io/zaanfa2/dhea-jordy/counting.jpg?updatedAt=1695350123523`;
export const BG_FOOTER = `https://ik.imagekit.io/zaanfa2/dhea-jordy/footer.jpg?updatedAt=1695350123551`;
export const BG_LIVESTREAM = ``;
export const BG_LOVESTORY = `https://ik.imagekit.io/zaanfa2/dhea-jordy/counting.jpg?updatedAt=1695350123523`;
export const BG_GIFTCARD = ``;
export const BG_FILTER = `https://ik.imagekit.io/zaanfa2/dhea-jordy/filter.jpg?updatedAt=1695712586707`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/zaanfa2/dhea-jordy/gallery-2.jpg?updatedAt=1695350127394`;

// images welcome section
export const IMG_LAMPION = `https://ik.imagekit.io/zaanfa2/dhea-jordy/welcoming-icon.png?updatedAt=1695350123555`;
export const IMG_WELCOME_1 = `https://ik.imagekit.io/zaanfa2/dhea-jordy/w1.jpg?updatedAt=1695350128293`;
export const IMG_WELCOME_2 = `https://ik.imagekit.io/zaanfa2/dhea-jordy/w2.jpg?updatedAt=1695350128342`;
export const IMG_WELCOME_3 = `https://ik.imagekit.io/zaanfa2/dhea-jordy/w3.jpg?updatedAt=1695350127704`;
export const IMG_WELCOME_4 = `https://ik.imagekit.io/zaanfa2/dhea-jordy/w4.jpg?updatedAt=1695350131166`;
export const IMG_WELCOME_5 = `https://user-images.githubusercontent.com/10141928/150647845-8df7fa24-7b06-4d0d-869d-dd78fc8db98d.jpeg`;

// couple image
export const IMG_MAN = `https://ik.imagekit.io/zaanfa2/dhea-jordy/man.jpg?updatedAt=1695350127488`;
export const IMG_GIRL = `https://ik.imagekit.io/zaanfa2/dhea-jordy/girl.jpg?updatedAt=1695350127756`;
export const IMG_PHOTO_COVER = `https://ik.imagekit.io/zaanfa2/dhea-jordy/photo-cover.jpeg?updatedAt=1695350127831`;
export const IMG_LOGO_WEDDING = `https://ik.imagekit.io/zaanfa2/dhea-jordy/wedding-detail-icon.png?updatedAt=1695350131378`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/149857534-17748784-0c8a-451a-80e0-941e2798927f.jpg`;
export const IMG_LOGO = ``;

// video assets
export const VIDEO_COVER = `https://user-images.githubusercontent.com/10141928/150642624-d39b2ace-41e7-4464-a1ed-1cadc519503a.mp4`;

// image photo gallery cover
export const IMG_PHOTO_1 = `https://ik.imagekit.io/zaanfa2/dhea-jordy/g1.jpg?updatedAt=1695350123392`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/zaanfa2/dhea-jordy/g2.jpg?updatedAt=1695350123516`;
export const IMG_PHOTO_3 = `https://ik.imagekit.io/zaanfa2/dhea-jordy/g3.jpg?updatedAt=1695350123518`;
export const COVER_GALLERY = `https://ik.imagekit.io/zaanfa2/dhea-jordy/gallery-3.jpg?updatedAt=1695350127489`;

// image icons
export const IMG_ICON_HAND = `https://user-images.githubusercontent.com/10141928/150661877-d975b35b-3e55-4333-b216-2f9df6ca2584.png`;
export const SONG_CDN_URL = `https://res.cloudinary.com/invitato/video/upload/v1640358887/template-song/LeeHi_-_ONLY_Lyrics_nzuosa.mp4`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;

// Collaboration
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE_WHITE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_PENTONE_BLACK = 'https://user-images.githubusercontent.com/103095433/193169607-562d8316-835d-4c15-a6b4-2b3c7cd22621.png';
export const LOGO_VENDOR_TIRTOWENING = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_PRAHASTA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';